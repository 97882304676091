(() => {
  const accordions = document.querySelectorAll('.accordion');
  if (!accordions) {
    return;
  }

  const uuidv4 = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise
      const r = (Math.random() * 16) | 0;
      // eslint-disable-next-line no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

  // Expand/Collapse All
  accordions.forEach((accordion) => {
    const collapseAll = accordion.querySelector('.collapse-all');
    const expandAll = accordion.querySelector('.expand-all');
    const accordionItems = accordion.querySelectorAll('.accordion__items');

    if (collapseAll) {
      collapseAll.addEventListener('click', (e) => {
        e.preventDefault();
        accordionItems.forEach((item) => {
          item.classList.remove('is-active');
        });

        window.tagular('beam', 'ElementClicked', {
          '@type': 'redventures.usertracking.v3.ElementClicked',
          correlationId: uuidv4(),
          webElement: {
            elementType: 'BUTTON',
            location: `sonic-accordion-${accordion.getAttribute(
              'data-accordion-type'
            )}`,
            position: 'SECONDARY',
            text: 'COLLAPSE ALL',
          },
          actionOutcome: 'COLLAPSE',
        });
      });
    }

    if (expandAll) {
      expandAll.addEventListener('click', (e) => {
        e.preventDefault();
        accordionItems.forEach((item) => {
          item.classList.add('is-active');
        });

        window.tagular('beam', 'ElementClicked', {
          '@type': 'redventures.usertracking.v3.ElementClicked',
          correlationId: uuidv4(),
          webElement: {
            elementType: 'BUTTON',
            location: `sonic-accordion-${accordion.getAttribute(
              'data-accordion-type'
            )}`,
            position: 'SECONDARY',
            text: 'EXPAND ALL',
          },
          actionOutcome: 'EXPAND',
        });
      });
    }

    // handle events and toggles in each accordion item
    accordionItems.forEach((item) => {
      const order = item.getAttribute('data-order');
      const header = item.querySelector('.header');
      const buttons = item.querySelectorAll('.button');

      const toggleAccordion = () => {
        const isActive = item.classList.toggle('is-active');
        header.setAttribute('aria-expanded', isActive);
        window.tagular('beam', 'ElementClicked', {
          '@type': 'redventures.usertracking.v3.ElementClicked',
          correlationId: uuidv4(),
          webElement: {
            elementType: 'BUTTON',
            location: `sonic-accordion-${accordion.getAttribute('data-accordion-type')}`,
            position: `SECTION ${order}`,
            text: header.innerText,
          },
          actionOutcome: item.classList.contains('is-active') ? 'EXPAND' : 'COLLAPSE',
        });
      };

      // toggle show/hide class for header
      if (header) {
        header.addEventListener('click', (e) => {
          e.preventDefault();
          toggleAccordion();
        });

        header.addEventListener('keydown', (e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            toggleAccordion();
          }
        });
      }

      // handle events for button clicks
      if (buttons) {
        buttons.forEach((button) => {
          button.addEventListener('click', (e) => {
            window.tagular('beam', 'ElementClicked', {
              '@type': 'redventures.usertracking.v3.ElementClicked',
              correlationId: uuidv4(),
              outboundUrl: e.href,
              webElement: {
                elementType: 'BUTTON',
                location: `sonic-accordion-${accordion.getAttribute(
                  'data-accordion-type'
                )}`,
                position: `SECTION ${order}`,
                text: header.innerText,
              },
              actionOutcome: 'EXTERNALLINK',
            });
          });
        });
      }
    });

    // Accordion Viewed
    let accordionViewed = false;
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !accordionViewed) {
            window.tagular('beam', 'ElementViewed', {
              '@type': 'redventures.usertracking.v3.ElementViewed',
              webElement: {
                elementType: 'SECTION',
                location: `sonic-accordion-${accordion.getAttribute(
                  'data-accordion-type'
                )}`,
                position: `SECTION ${entries[0].target
                  .querySelector('.header')
                  .parentElement.getAttribute('data-order')}`,
                text: entries[0].target.querySelector('.title').innerText,
              },
            });
            accordionViewed = true;
          }
        },
        {
          threshold: 0.5,
        }
      );
      observer.observe(accordion);
    }

    // View more btn click -> display all accordion items
    const showAllButton = accordion.querySelector('.view-more-js');
    if (showAllButton) {
      showAllButton.addEventListener('click', (e) => {
        e.preventDefault();
        showAllButton.closest('.view-more-button').classList.add('hidden');
        accordion.classList.add('accordion__show-all');
      });
    }
  });
})();
